<template>
  <div class="calculatorContainer">
    <sui-container>
      <p class="calculatorTitle">
        {{ $t("rooms.add.calculatorTitle") }}
      </p>
      <sui-grid verticalAlign="bottom" class="centered ">
        <div class="doubling six column stackable row">
          <div
            class="four wide computer three wide large screen three wide widescreen column"
          >
            <div>
              <b>{{ $t("rooms.add.selectLabel") }}</b>
            </div>
            <sui-dropdown
              fluid
              :class="isDropdownEmpty"
              :options="options"
              v-bind:placeholder="$t('rooms.add.selectPlaceholder')"
              search
              selection
              @select="checkVentilation"
              v-model="roomSelected"
            ></sui-dropdown>
          </div>
          <div class="column">
            <div class="lengthColumn">
              <b>{{ $t("rooms.add.lengthLabel") }}</b>
            </div>
            <sui-input
              :class="'calculatorInput length fluid ' + isLengthEmpty"
              v-bind:placeholder="$t('rooms.add.lengthPlaceholder')"
              v-model="length"
              @change="moreThanTwoDigits(length,'length')"
              @keyup="multiple(length, width)"
              maxlength="9"
              :disabled="isDisabled"
            ></sui-input>
          </div>
          <div class="two wide large screen column widthColumn">
            <div class="widthLabel">
              <b>{{ $t("rooms.add.widthLabel") }}</b>
            </div>
            <sui-input
              :class="'calculatorInput width fluid ' + isWidthEmpty"
              v-bind:placeholder="$t('rooms.add.widthPlaceholder')"
              v-model="width"
              @change="moreThanTwoDigits(width,'width')"
              @keyup="multiple(length, width)"
              maxlength="9"
              :disabled="isDisabled"
            ></sui-input>
          </div>
          <div class="column">
            <div>
              <b>{{ $t("rooms.add.areaLabel") }}[m<sup>2</sup>]</b>
            </div>
            <sui-input
              :class="'calculatorInput ' + isAreaEmpty"
              v-bind:placeholder="$t('rooms.add.areaPlaceholder')"
              v-model="area"
              @keyup="dotReplace(area)"
              :value="area"
              maxlength="20"
            ></sui-input>
          </div>
          <div class="column">
            <div>
              <b>{{ $t("rooms.add.roomName") }}</b>
            </div>
            <sui-input
              v-bind:placeholder="$t('rooms.add.roomNamePlaceholder')"
              v-model="name"
              :value="name"
              class="fluid"
            ></sui-input>
          </div>
          <div class="column">
            <div>
              <b>{{ $t("rooms.add.peopleLabel") }}</b>
            </div>
            <sui-input
              :class="'calculatorInput ' + isNumberOfPeopleEmpty"
              v-bind:placeholder="$t('rooms.add.peoplePlaceholder')"
              v-model="people"
              :value="people"
              maxlength="20"
              :disabled="!checkVentilation()"
            ></sui-input>
          </div>
        </div>
        <div class="row">
          <div
            class="six wide computer three wide large screen column three wide widescreen column"
          >
            <div @click="addRoom">
              <Button v-bind:title="$t('rooms.add.addBtn')" />
            </div>
          </div>
        </div>
      </sui-grid>
    </sui-container>
  </div>
</template>

<script>
import Button from "@/components/controls/Button";

export default {
  name: "RoomsAdd",
  components: {
    Button
  },
  data() {
    return {
      roomSelected: null,
      name: "",
      length: "",
      width: "",
      area: "",
      people: "",
      rooms: [],
      options: [],
      isDropdownEmpty: "",
      isAreaEmpty: "",
      isNumberOfPeopleEmpty: "",
      isWidthEmpty: "",
      isLengthEmpty: "",
      isDisabled: false
    };
  },
  mounted() {
    this.$store.dispatch("rooms/fetchAll").then(response => {
      this.rooms = response;
      this.rooms.forEach(val => {
        this.options.push({
          key: val.id,
          text: val.name,
          value: val.id
        });
      });
    });
  },

  watch: {
    roomSelected: function() {
      if (this.roomSelected !== null) {
        this.isDropdownEmpty = "";
        this.checkVentilation(this.roomId)
          ? (this.isNumberOfPeopleEmpty = "")
          : "";
      }
    },
    area: function() {
      let area = this.area.replace(/,/, ".");
      // eslint-disable-next-line no-useless-escape
      this.area = this.area.replace(/^\-/, "");
      this.area = this.area.replace(/^\+/, "");
      if (!isNaN(area)) {
        this.isAreaEmpty = "";
      } else {
        this.isAreaEmpty = "error";
      }
    },
    people: function() {
      this.people = this.people.replace(/,/, "");
      this.people = this.people.replace(/\./, "");
      if (!isNaN(this.people)) {
        this.isNumberOfPeopleEmpty = "";
      } else {
        this.isNumberOfPeopleEmpty = "error";
      }
    },
    length: function() {
      // eslint-disable-next-line no-useless-escape
      this.length = this.length.replace(/^\-/, "");
      this.length = this.length.replace(/^\+/, "");
      if (this.length && isNaN(this.length.replace(/,/g, "."))) {
        this.isLengthEmpty = "error";
      } else {
        this.isLengthEmpty = "";
      }
    },
    width: function() {
      // eslint-disable-next-line no-useless-escape
      this.width = this.width.replace(/^\-/, "");
      this.width = this.width.replace(/^\+/, "");
      if (this.width && isNaN(this.width.replace(/,/g, "."))) {
        this.isWidthEmpty = "error";
      } else {
        this.isWidthEmpty = "";
      }
    }
  },
  methods: {
    addRoom: function() {
      let roomId = this.roomSelected;
      let room = this.$store.getters["rooms/getRoomById"](this.roomSelected);
      let name = this.name;
      let area = this.area.replace(/,/g, ".");
      let width = this.width;
      if (width){
        width = width.replace(/,/g, ".");
      }
      let length = this.length;
      if (length){
        length = length.replace(/,/g, ".");
      }
      let people = this.people;

      area = area.replace(/,\s*$/, "");
      width = width.replace(/,\s*$/, "");
      length = length.replace(/,\s*$/, "");

      if (roomId && area && !isNaN(area) && area > 0) {
        this.isAreaEmpty = "";
        this.isDropdownEmpty = "";

        if (this.checkVentilation(roomId)) {
          if (people && !isNaN(people) && people > 0) {
            this.$store.dispatch("rooms/addRoomToList", {
              name,
              room,
              area,
              width,
              length,
              people
            });
            this.isNumberOfPeopleEmpty = "";
            this.roomSelected = null;
            this.area = "";
            this.width = "";
            this.length = "";
            this.people = "";
            this.name = "";
            this.isDisabled = false;
          } else {
            area === "" ? (this.isAreaEmpty = "error") : "";
            isNaN(area) || area <= 0 ? (this.isAreaEmpty = "error") : "";
            roomId === null ? (this.isDropdownEmpty = "error") : "";
            people === "" ? (this.isNumberOfPeopleEmpty = "error") : "";
            if (area === "" && (isNaN(width) || isNaN(length))) {
              isNaN(length) ? (this.isLengthEmpty = "error") : "";
              isNaN(width) ? (this.isWidthEmpty = "error") : "";
            }
          }
        } else {
          this.$store.dispatch("rooms/addRoomToList", {
            name,
            room,
            area,
            width,
            length,
            people
          });
          this.roomSelected = null;
          this.area = "";
          this.width = "";
          this.length = "";
          this.people = "";
          this.name = "";
          this.isDisabled = false;
        }
      } else {
        area === "" ? (this.isAreaEmpty = "error") : "";
        isNaN(area) || area <= 0 ? (this.isAreaEmpty = "error") : "";
        roomId === null ? (this.isDropdownEmpty = "error") : "";
        people === "" ? (this.isNumberOfPeopleEmpty = "error") : "";
        if (area === "" && (isNaN(width) || isNaN(length))) {
          isNaN(length) ? (this.isLengthEmpty = "error") : "";
          isNaN(width) ? (this.isWidthEmpty = "error") : "";
        }
      }
    },
    multiple: function(x, y) {
      this.isLengthEmpty === "error" && isNaN(x)
        ? ""
        : (this.isLengthEmpty = "");
      this.isWidthEmpty === "error" && isNaN(y) ? "" : (this.isWidthEmpty = "");
      if (!this.isDisabled) {
        this.length = x.replace(/\./g, ",");
        this.width = y.replace(/\./g, ",");
        x = parseFloat(x.replace(/,/g, "."));
        y = parseFloat(y.replace(/,/g, "."));
        let area = x * y * 0.0001;
        area = Math.round(area * 10000) / 10000;
        area = area.toString();
        area = area.replace(/\./g, ",");
        area === "NaN" ? (area = "") : "";
        this.area = area;
        this.isAreaEmpty = "";
      } else {
        this.width = "";
        this.length = "";
      }
    },
    dotReplace(area) {
      let a = area.replace(/\./g, ",");
      this.area = a;
      this.width = "";
      this.length = "";
      if (this.area.length > 0) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    checkVentilation: function() {
      let room = this.$store.getters["rooms/getRoomById"](this.roomSelected);
      return room ? room.room_type === "supply" : false;
    },
    moreThanTwoDigits(value,input){
      if(input == 'width'){
        this.width = value;
      }
      if(input == 'length'){
        this.length = value;
      }
    }
  }
};
</script>
