<template>
  <div>
    <Rooms />
  </div>
</template>

<script>
import Rooms from "@/components/Rooms.vue";
export default {
  name: "Homepage",
  components: {
    Rooms
  }
};
</script>
