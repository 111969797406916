var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"emptyListOfRooms"},[(this.loader === false && this.images.length === 0)?_c('sui-container',{staticClass:"bgImg",staticStyle:{"background":"#fcfcfc"},style:({
    'background-image':
      'url(' + require('@/assets/images/MaskGroup.png') + ')'
  }),attrs:{"text-align":"center"}},[_c('sui-segment',[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t("rooms.empty.header")))])]),_c('em',[_vm._v(_vm._s(_vm.$t("rooms.empty.quoteHeader"))+" "),_c('br'),_vm._v(" \""+_vm._s(_vm.quote || _vm.$t("rooms.empty.text"))+"\"")])])],1):_vm._e(),(this.loader === false && this.images.length !== 0)?_c('sui-container',{staticClass:"bgImg-desktop",style:({
    'background-image':
      'url(' + this.baseUrl + this.images.desktop + ')'
  }),attrs:{"text-align":"center"}}):_vm._e(),(this.loader === false && this.images.length !== 0)?_c('sui-container',{staticClass:"bgImg-mobile",style:({
    'background-image':
      'url(' + this.baseUrl + this.images.mobile + ')'
  }),attrs:{"text-align":"center"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }