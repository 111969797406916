<template>
    <div class="emptyListOfRooms">
        <sui-container
            class="bgImg"
            text-align="center"
            style="background: #fcfcfc"
            v-if="this.loader === false && this.images.length === 0"
            :style="{
        'background-image':
          'url(' + require('@/assets/images/MaskGroup.png') + ')'
      }"
        >
            <sui-segment>
                <p>
                    <b>{{ $t("rooms.empty.header") }}</b>
                </p>
                <em
                >{{ $t("rooms.empty.quoteHeader") }} <br/>
                    "{{ quote || $t("rooms.empty.text") }}"</em
                >
            </sui-segment>
        </sui-container>
        <sui-container
            class="bgImg-desktop"
            text-align="center"
            v-if="this.loader === false && this.images.length !== 0"
            :style="{
        'background-image':
          'url(' + this.baseUrl + this.images.desktop + ')'
      }"
        >
        </sui-container>
        <sui-container
            class="bgImg-mobile"
            text-align="center"
            v-if="this.loader === false && this.images.length !== 0"
            :style="{
        'background-image':
          'url(' + this.baseUrl + this.images.mobile + ')'
      }"
        >
        </sui-container>
    </div>
</template>

<script>
import Repository from "@/repository";

export default {
    name: "RoomsEmpty",
    data() {
        return {
            quote: "",
            images: [],
            baseUrl: "",
            loader: true
        };
    },
    mounted() {
        Repository.get(this.$i18n.locale + "/api/public/quote").then(response => {
            this.quote = response.data.data.text;
            this.images = response.data.images;
            this.baseUrl = Repository.defaults.baseURL;
            this.loader = false;
        });
    }
};
</script>

<style>
.bgImg-desktop {
    display: block !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 400px;
}
.bgImg-mobile {
    display: none !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 400px;
}
@media (max-width: 768px) {
    .bgImg-desktop {
        display: none !important;
    }
    .bgImg-mobile {
        display: block !important;
    }
}
</style>
