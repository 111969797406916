<template>
  <div class="listOfRooms">
    <sui-container>
      <div class="column">
        <p>
          <b>{{ $t("rooms.list.header") }}</b>
        </p>
      </div>
    </sui-container>
    <sui-container>
      <table class="ui table striped">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("rooms.list.table.name") }}</th>
            <th>{{ $t("rooms.list.table.area") }}[m<sup>2</sup>]</th>
            <th>{{ $t("rooms.list.table.airStream") }} [m<sup>3</sup>/h]</th>
            <th>{{ $t("rooms.list.table.type") }}</th>
            <th></th>
          </tr>
        </thead>
        <draggable v-model="$store.state.rooms.roomsList" tag="tbody">
          <template v-for="(room, index) in $store.state.rooms.roomsList">
            <sui-table-row :key="index" class="draggable-cursor">
              <sui-table-cell>
                <span class="mobileTh"># </span>{{ index + 1 }}</sui-table-cell
              >
              <sui-table-cell>
                <span class="mobileTh">
                  {{ $t("rooms.list.table.name") }}:
                </span>
                {{ room.name }}
              </sui-table-cell>
              <sui-table-cell>
                <span class="mobileTh">
                  {{ $t("rooms.list.table.area") }}:
                </span>
                {{ replaceWithComma(room.area) }}
              </sui-table-cell>
              <sui-table-cell>
                <span class="mobileTh">
                  {{ $t("rooms.list.table.airStream") }}:
                </span>
                {{ room.air_stream }}
              </sui-table-cell>
              <sui-table-cell>
                <span class="mobileTh">
                  {{ $t("rooms.list.table.type") }}:
                </span>
                {{ $t("rooms.list.table." + room.room_type) }}
              </sui-table-cell>
              <sui-table-cell class="right aligned">
                <div class="ui grid">
                  <div class="computer only row">
                    <div class="column right aligned">
                      <sui-button size="tiny" @click="edit(index)">
                        {{ $t("rooms.list.table.editBtn") }}
                      </sui-button>
                      <sui-button
                        size="tiny"
                        icon="minus"
                        @click="removeFromList(index)"
                      ></sui-button>
                    </div>
                  </div>
                  <div class="tablet mobile only row">
                    <div class="column editMobileBtn">
                      <sui-button
                        class=""
                        size="tiny"
                        icon="edit"
                        @click="edit(index)"
                      >
                      </sui-button>
                    </div>
                    <div class="column">
                      <sui-button
                        size="tiny"
                        icon="minus"
                        @click="removeFromList(index)"
                      ></sui-button>
                    </div>
                  </div>
                </div>
              </sui-table-cell>
            </sui-table-row>
          </template>
        </draggable>
      </table>
    </sui-container>
    <!-- modal -->
    <sui-modal v-model="open" class="editModal" @click="confirm">
      <sui-modal-content>
        <div class="modal-top-bar">
          <div class="text">
            {{ $t("rooms.list.modal.header") }}: <b>{{ name }}</b>
          </div>
          <div class="cross" v-on:click="closeModal">x</div>
        </div>
        <form class="ui form">
          <div class="field">
            <label>{{ $t("rooms.add.roomName") }}</label>
            <input
              class="modalWidth"
              type="text"
              :placeholder='$t("rooms.add.roomName")'
              v-model="name"
            />
          </div>
          <div :class="'field ' + isLenghtError">
            <label>{{ $t("rooms.add.lengthLabel") }}</label>
            <input
              class="modalWidth"
              type="text"
              :placeholder='$t("rooms.add.lengthPlaceholder")'
              v-model="length"
              maxlength="9"
              @change="moreThanTwoDigits(length,'length')"
              @keyup="multiple(length, width)"
            />
          </div>
          <div :class="'field ' + isWidthError">
            <label>{{ $t("rooms.add.widthLabel") }}</label>
            <input
              class="modalLenght"
              type="text"
              :placeholder="$t('rooms.add.widthPlaceholder')"
              v-model="width"
              maxlength="9"
              @keyup="multiple(length, width)"
              @change="moreThanTwoDigits(width,'width')"
            />
          </div>
          <div :class="'field ' + isAreaEmpty">
            <label>{{ $t("rooms.list.table.area") }} [m<sup>2</sup>]</label>
            <input
              class="modalResult"
              type="text"
              :placeholder="$t('rooms.add.areaPlaceholder')"
              v-model="area"
              maxlength="10"
              @keyup="dotReplace(area, 'area')"
            />
          </div>
          <div :class="'field ' + isAirStreamEmpty">
            <label
              >{{ $t("rooms.list.table.airStream") }} [m<sup>3</sup>/h]</label
            >
            <input
              class="modalResult"
              type="text"
              :placeholder="$t('rooms.list.table.airStream')"
              v-model="airStream"
              maxlength="10"
            />
          </div>
          <div :class="'field ' + isPeopleEmpty" v-show="roomType === 'supply'">
            <label>{{ $t("rooms.add.peopleLabel") }}</label>
            <input
              type="text"
              :placeholder="$t('rooms.add.peoplePlaceholder')"
              v-model="people"
              maxlength="9"
            />
          </div>
        </form>
        <div class="ui right aligned grid">
          <div class="row">
            <div
              class="right floated three wide computer sixteen wide mobile column"
            >
              <sui-button fluid @click="closeModal">
                {{ $t("rooms.list.modal.cancleButton") }}
              </sui-button>
            </div>
            <div class="three wide computer  sixteen wide mobile column">
              <sui-button icon="check" fluid negative @click="confirm">
                {{ $t("rooms.list.modal.saveButton") }}
              </sui-button>
            </div>
          </div>
        </div>
      </sui-modal-content>
    </sui-modal>
    <!-- Finish section -->
    <sui-container>
      <div class="ui message error" v-show="displayMessage">
        <div class="header">
          {{$t("rooms.list.finishSection.error.listShouldContain")}}:
        </div>
        <ul class="list">
          <li>{{$t("rooms.list.finishSection.error.atLeastOne")}} <b>{{$t("rooms.list.finishSection.error.exhaust")}}</b></li>
          <li>{{$t("rooms.list.finishSection.error.atLeastOne")}} <b>{{$t("rooms.list.finishSection.error.supply")}}</b></li>
        </ul>
      </div>
    </sui-container>
    <sui-container class="finishSection">
      <div class="ui equal width grid">
        <div class="row centered">
          <div class="twelve wide column center aligned">
            <p>{{ $t("rooms.list.finishSection.contents") }}</p>
          </div>
        </div>
        <div class="row centered">
          <div class="six wide column center aligned">
            <div class="btn">
              <sui-button fluid v-on:click="calculateRoomsItems">{{
                $t("rooms.list.finishSection.button")
              }}</sui-button>
            </div>
          </div>
        </div>
      </div>
    </sui-container>
  </div>
</template>
<script>
import draggable from 'vuedraggable';

export default {
  name: "RoomsList",
  components: {
      draggable
  },
  data() {
    return {
      errors: [],
      roomsList: [],
      open: false,
      current: null,
      name: "",
      width: "",
      length: "",
      area: "",
      number: "",
      airStream: "",
      roomType: "",
      people: "",
      index: "",
      isLenghtError: "",
      isWidthError: "",
      isAirStreamEmpty: "",
      isAreaEmpty: "",
      isPeopleEmpty: "",
      displayMessage: false
    };
  },
  mounted() {
    this.roomsList = this.$store.state.rooms.roomsList;
    // let table = document.getElementById('sortable-rooms');
  },
  watch: {
    area: function() {
      if (this.area !== null) {
        this.area = this.area.replace(/\./, ",");
        this.area = this.area.replace(/\+/, "");
        this.area = this.area.replace(/-/, "");
        let area = this.area.replace(/,/, ".");
        isNaN(area) ? (this.isAreaEmpty = "error") : (this.isAreaEmpty = "");
        this.area > 9999999999 ? (this.area = 9999999999) : "";
      }
    },
    people: function() {
      if (this.people !== null) {
        try {
          this.people = this.people.replace(/-/, "");
          this.people = this.people.replace(/,/, "");
          this.people = this.people.replace(/\./, "");
          this.people = this.people.replace(/\+/, "");
          this.people > 999999999 ? (this.people = 999999999) : "";
          // eslint-disable-next-line no-empty
        } catch {}
        isNaN(this.people)
          ? (this.isPeopleEmpty = "error")
          : (this.isPeopleEmpty = "");
      }
    },
    length: function() {
      if (this.length !== null) {
        this.length = this.length.replace(/\./, "");
        // this.length = this.length.replace(/,/, "");
        // eslint-disable-next-line no-useless-escape
        this.length = this.length.replace(/^\-/, "");
        this.length = this.length.replace(/^\+/, "");
        let len = this.length.replace(/,/, ".");
        isNaN(len) ? (this.isLenghtError = "error") : (this.isLenghtError = "");
      }
    },
    airStream: function() {
      try {
        if (this.airStream !== null) {
          this.airStream = this.airStream.replace(/\+/, "");
          this.airStream = this.airStream.replace(/\./, "");
          this.airStream = this.airStream.replace(/,/, "");
          this.airStream = this.airStream.replace(/-/, "");
          let air = this.airStream.replace(/,/, ".");
          isNaN(air)
            ? (this.isAirStreamEmpty = "error")
            : (this.isAirStreamEmpty = "");
          this.airStream > 9999999999 ? (this.airStream = 9999999999) : "";
        }
        // eslint-disable-next-line no-empty
      } catch {}
    },
    width: function() {
      if (this.width !== null) {
        this.width = this.width.replace(/\./, "");
        // this.width = this.width.replace(/,/, "");
        this.width = this.width.replace(/\+/, "");
        this.width = this.width.replace(/-/, "");
        let width = this.width.replace(/,/, ".");
        isNaN(width) ? (this.isWidthError = "error") : (this.isWidthError = "");
      }
    }
  },
  methods: {
    edit: function(index) {
      let room = this.roomsList[index];
      this.index = index;
      this.name = room.name;
      this.current = room.room_id;
      this.width = room.width;
      if (this.width){
        this.width = this.width.replace(/\./g, ",");
      }
      this.length = room.length;
      if (this.length){
        this.length = this.length.replace(/\./g, ",");
      }
      this.area = room.area;
      this.number = room.number;
      this.airStream = room.air_stream;
      this.roomType = room.room_type;
      this.people = room.people;
      this.open = true;
    },
    confirm: function() {
      let area = this.roomsList[this.index].area;
      let airStream = this.roomsList[this.index].air_stream;
      let people = this.roomsList[this.index].people;

      if (area){
        area = area.replace(/,/g, ".");
      }

      let areaValue = this.area;
      if (areaValue){
        areaValue = areaValue.replace(/,/g, ".");
      }
      let airStreamValue = this.airStream;
      try {
        if (airStreamValue){
          airStreamValue = airStreamValue.replace(/,/, ".");
        }
        // eslint-disable-next-line no-empty
      } catch {}

      if (isNaN(people) || people === null) {
        if (
          !isNaN(areaValue) &&
          areaValue > 0 &&
          !isNaN(airStreamValue) &&
          airStreamValue > 0
        ) {
          this.roomsList[this.index].area = parseFloat(areaValue).toFixed(2);
          try {
            airStreamValue = airStreamValue.replace(/,/, ".");
            // eslint-disable-next-line no-empty
          } catch {}
          this.roomsList[this.index].air_stream = parseInt(airStreamValue);
          (this.isAirStreamEmpty = ""), (this.isAreaEmpty = "");

          this.roomsList[this.index].name = this.name;

          if (this.width){
            this.roomsList[this.index].width = this.width.replace(/,/g, ".");
          }
          if (this.length){
            this.roomsList[this.index].length = this.length.replace(/,/g, ".");
          }

          this.$store.dispatch("rooms/updateRoomsList", this.roomsList);
          // this.$store.dispatch("rooms/updateErrors", '');
          this.clearError();
          this.open = false;
        } else {
          isNaN(areaValue) || areaValue <= 0
            ? (this.isAreaEmpty = "error")
            : " ";
          isNaN(this.airStream) || this.airStream <= 0
            ? (this.isAirStreamEmpty = "error")
            : " ";
          this.roomsList[this.index].area = parseFloat(area).toFixed(2);
          this.roomsList[this.index].air_stream = parseInt(airStream);
        }
      } else {
        if (
          !isNaN(areaValue) &&
          areaValue > 0 &&
          !isNaN(airStreamValue) &&
          airStreamValue > 0 &&
          !isNaN(this.people) &&
          this.people > 0
        ) {
          this.roomsList[this.index].area = parseFloat(areaValue).toFixed(2);
          try {
            if (airStreamValue){
              airStreamValue = airStreamValue.replace(/,/, ".");
            }
            // eslint-disable-next-line no-empty
          } catch {}
          this.roomsList[this.index].air_stream = parseInt(airStreamValue);
          this.roomsList[this.index].people = parseFloat(this.people);
          (this.isAirStreamEmpty = ""),
            (this.isAreaEmpty = ""),
            (this.isPeopleEmpty = "");

          this.roomsList[this.index].name = this.name;
          if (this.width){
            this.roomsList[this.index].width = this.width.replace(/,/g, ".");
          }
          if(this.length){
            this.roomsList[this.index].length = this.length.replace(/,/g, ".");
          }

          this.$store.dispatch("rooms/updateRoomsList", this.roomsList);
          // this.$store.dispatch("rooms/updateErrors", '');
          this.clearError();
          this.open = false;
        } else {
          isNaN(areaValue) || areaValue <= 0
            ? (this.isAreaEmpty = "error")
            : " ";
          isNaN(this.airStream) || this.airStream <= 0
            ? (this.isAirStreamEmpty = "error")
            : " ";
          isNaN(this.people) || this.people <= 0
            ? (this.isPeopleEmpty = "error")
            : "";
          this.roomsList[this.index].area = parseFloat(area).toFixed(2);
          this.roomsList[this.index].air_stream = parseInt(airStream);
          this.roomsList[this.index].people = parseFloat(people);
        }
      }
    },
    removeFromList: function(index) {
      this.roomsList.splice(index, 1);
      this.$store.dispatch("rooms/updateRoomsList", this.roomsList);
    },
    multiple: function(x, y) {
      if (
        x !== null &&
        y !== null &&
        !isNaN(x.replace(/,/g, ".")) &&
        !isNaN(y.replace(/,/g, "."))
      ) {
        this.length = x;
        this.width = y;
        x = parseFloat(x.replace(/,/g, "."));
        y = parseFloat(y.replace(/,/g, "."));
        let area = x * y * 0.0001;
        area = Math.round(area * 100) / 100;
        area = area.toString();
        area = area.replace(/\./g, ",");
        area === "NaN" ? (area = "") : "";
        this.area = area;
      }
    },
    checkTypes: function() {
      let exhaust = 0,
        supply = 0;
      this.$store.state.rooms.roomsList.forEach(room => {
        room.room_type === "exhaust" ? exhaust++ : supply++;
      });
      return [exhaust, supply];
    },
    calculateRoomsItems: function() {
      if (this.errors.length <= 0) {
        let height = this.$store.state.rooms.height;
        let exhaust = this.checkTypes()[0];
        let supply = this.checkTypes()[1];

        if (isNaN(height) || height <= 0) {
          this.$store.dispatch("rooms/setHeight", "");
        } else {
          this.$store.dispatch("rooms/setHeight", height);
        }

        if (exhaust > 0 && supply > 0 && !isNaN(height) && height > 0) {
          this.$store.dispatch("rooms/calculateRoomsItems").then(response => {
            if (response) {
              this.$router.push({ name: "result" });
            }
          });

          this.displayMessage = false;
        } else {
          this.displayMessage = true;
        }
      }
    },
    closeModal: function() {
      this.isAirStreamEmpty = "";
      this.isAreaEmpty = "";
      this.isPeopleEmpty = "";
      this.open = false;
    },
    dotReplace(value, input) {
      if (input === "area") {
        let val = value.replace(/\./g, ",");
        this.length = "";
        this.width = "";
        this.area = val;
      } else {
        let val = value.replace(/\./g, ",");
        this.airStream = val;
      }
    },
    replaceWithComma(string) {
      let floatNumber = parseFloat(string).toFixed(2);
      string = floatNumber.toString();
      return string.replace(".", ",");
    },
    clearError() {
      this.$store.dispatch("rooms/calculateRoomsItems").then(response => {
        if (response) {
          this.$store.dispatch("rooms/updateErrors", "");
        }
      });
    },
    moreThanTwoDigits(value,input){
        if(input == 'width'){
          let val = value;
          val = val.replace(/,/g, ".");
          val = Math.round(val * 100) / 100;
          val = val.toString().replace(/\./g, ',');
          this.width = val;
        }
        if(input == 'length'){
          let val = value;
          val = val.replace(/,/g, ".");
          val = Math.round(val * 100) / 100;
          val = val.toString().replace(/\./g, ',');
          this.length = val;
        }
    }
  }
};
</script>

<style>
.draggable-cursor:hover {
    cursor: move;
}
</style>
