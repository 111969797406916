<template>
  <div
    class="backgroundBanner"
    :style="{
      'background-image': 'url(' + require('@/assets/images/textBg.png') + ')'
    }"
  >
    <sui-container>
      <div class="ui grid equal width">
        <div class="row centered">
          <div class="column fluid">
            {{ $t("banner.mainText") }}
          </div>
        </div>
        <div class="doubling stackable row">
          <div class="seven wide computer column first">
            <strong>{{ $t("banner.heightLabel") }}</strong>
          </div>
          <div class="three wide computer column second">
            <sui-input
              :class="isEmpty"
              v-bind:placeholder="$t('banner.heightPlaceholder')"
              v-model="height"
              focus
              @change="setHeight"
              @keyup="editHeight"
              maxlength="10"
            ></sui-input>
          </div>
          <div class="twelve wide tablet six wide computer column third">
            {{ $t("banner.heightInfo") }}
          </div>
        </div>
      </div>
    </sui-container>
  </div>
</template>

<script>
export default {
  name: "Banner",
  components: {},
  data() {
    return {
      columnWidth: 7,
      height: this.$store.state.rooms.height,
      isEmpty: ""
    };
  },
  mounted() {
    if (this.height === "") {
      this.isEmpty = "error";
    }
  },
  watch: {
    height: function() {
      this.height = this.height.replace(/-/, "");
      this.height = this.height.replace(/\+/, "");
      this.height = this.height.replace(/\./, ",");
      this.height = this.height.replace(/^0+/, "");
      var count = (this.height.match(/,/g) || []).length;
      var height = this.height.replace(/,/, ".");

      if (isNaN(height) || count > 2) {
        this.isEmpty = "error";
      } else {
        this.isEmpty = "";
      }
    }
  },
  methods: {
    setHeight: function() {
      this.$store.dispatch("rooms/setHeight", this.height.replace(/,/, "."));
    },
    editHeight: function() {
      this.height ? (this.isEmpty = "") : (this.isEmpty = "error");
    }
  }
};
</script>
