<template>
  <div>
    <Banner />
    <RoomsAdd />
    <sui-container>
      <sui-message error v-if="errors.length > 0">
        <sui-message-list>
          <span v-for="(error, index) in errors" :key="index">
            <sui-message-item v-if="Object.keys(error)[0]">
              <span>
                {{ $t("rooms.errors." + Object.keys(error)[0]) }}
                {{
                  errors.indexOf(error) > 0
                    ? "w pomieszczeniu nr. " + errors.indexOf(error) + " -"
                    : " -"
                }}
                {{ Object.values(error)[0] }}
              </span>
            </sui-message-item>
          </span>
        </sui-message-list>
      </sui-message>
    </sui-container>
    <RoomsList v-show="showRoomsList" />
    <RoomsEmpty v-show="!showRoomsList" />
  </div>
</template>

<script>
import RoomsAdd from "@/components/rooms/Add.vue";
import Banner from "@/components/Banner.vue";
import RoomsList from "@/components/rooms/List.vue";
import RoomsEmpty from "@/components/rooms/Empty.vue";

export default {
  name: "Homepage",
  data() {
    return {
      errors: this.$store.state.rooms.errors,
      rooms: [],
      showRoomsList: false
    };
  },
  mounted() {
    this.rooms = this.$store.state.rooms.roomsList;
  },
  computed: {
    errorsChanged() {
      return this.$store.state.rooms.errors;
    }
  },
  watch: {
    rooms: function() {
      this.rooms.length
        ? (this.showRoomsList = true)
        : (this.showRoomsList = false);
    },
    errorsChanged(value) {
      this.errors = value;
    }
  },
  components: {
    Banner,
    RoomsAdd,
    RoomsList,
    RoomsEmpty
  }
};
</script>
