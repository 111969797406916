<template>
  <div class="btn">
    <sui-button fluid>{{ title }}</sui-button>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    title: String
  }
};
</script>
